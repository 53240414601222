import BackendService from './BackendService';
const singleName = 'topten';
//const pluralName = singleName+'s';




export default class TopTenService extends BackendService {

    constructor() {
        super();
    }
   

    create(data) {
        return this.httpCall(singleName,{
            method: 'POST',
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' }
        });

    }

    update(data) {
        return this.httpCall(singleName+'/'+data.id,{
            method: 'PUT',
            data: data,
        });
    }
    toggleStatus(id){
        return this.httpCall(singleName+'/'+id+'/toggle',{
            method: 'PUT',
        });
    }

    delete(id) {
        return this.httpCall(singleName+'/'+id,{
            method: 'delete'
        });
    }

    getById(id) {

        return this.httpCall(singleName+'/'+id);

        //return this.httpCall('property' + property_id + '/users');
    }

    getList(params) {
        //console.log('get rooms');

        //return testItems;
        return this.httpCall(singleName,{
            params: params,
        });
    }
    createImage(id, data, action) {
        return this.httpCall(singleName+'/'+id+'/image', {
            method: action,
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
    }
    deleteImage(id) {
        return this.httpCall(singleName+'/'+id+'/image',{
            method: 'delete'
        });
    }
    


}
