<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import TopTenService from "@/services/TopTenService";
const itemService = new TopTenService();
import moment from "moment";
import Swal from "sweetalert2";
import draggable from 'vuedraggable'
//import { helpers } from "chart.js";
/*import MovementService from "@/services/MovementService";
const itemService = new MovementService();*/

import HelperJS from "@/functions/general";
const helper = new HelperJS();

/**
 * Invoice-list component
 */
const nameSeccion = 'Los más vendidos';
const namePlural = nameSeccion;
const routeParentName = 'topten';
export default {
  page: {
    title: "Listado de "+ namePlural,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, draggable },
  data() {
    return {
      title: namePlural,
      items: [
        {
          text: namePlural,
        },
        {
          text: "Listado de "+ namePlural,
          active: true,
        },
      ],
      filterBar:{
        currency: '',
        type: '',
        from: null,
        to: null
      },
      invoicelist: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      info_page:{
        routeParentName: null,
        nameSeccion: null,
      },
      fields: [
             
        {
          key: "arreglo.name",
          label: "Nombre",
          sortable: true,
        },
        {
          key: "order",
          label: "Orden"
        },
        
        {
          key: "action",
          label: "Acciones",
          sortable: true,
        }
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.invoicelist.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  created(){

    this.initPage();
    this.chargeItems();
  },

  methods: {
    /**
     * Search the table data with search input
     */
    initPage(){
      this.info_page.routeParentName = routeParentName;
      this.info_page.nameSeccion = nameSeccion;
    },
    async chargeItems(){

        await itemService.getList().then(response=>{
          this.invoicelist = response.data.object_data;
        });
        
    },
    
    applyFilter(){
      let params = {...this.filterBar};
      if(params.from!==null){
        params.from = moment(params.from, 'DD-MMM-YYYY').format('YYYY-MM-DD');
      }
      if(params.to!==null){        
        params.to = moment(params.to, 'DD-MMM-YYYY').format('YYYY-MM-DD');
      }
     
       itemService.getList(params).then(response=>{
          this.invoicelist = response.data.object_data;
        });

    },
     routeGo(textRoute){
      this.$router.push({
        path: textRoute,
      });
    },
    
    deleteElement(id){
      itemService.delete(id).then(async ()=>{

         this.successForm();
         await this.chargeItems();

        await this.processUpdate();
      })
    },
    async processUpdate(){
      let listArray = JSON.parse(JSON.stringify(this.invoicelist));
      console.log(listArray, this.invoicelist);
      await helper.asyncForEach(listArray, item=>{
        itemService.update(item)
      })
    },
    async updateForm(){
      await this.processUpdate();
      await this.successSaveForm(this.info_page.routeBack);
      
      this.$v.$touch();
  
  },
    successForm(){
      
      Swal.fire("¡Operación exitosa!", "El registro fué eliminado", "success");
    },

    
    successSaveForm(){
      Swal.fire("¡Operación exitosa!", "Información guardada con éxito", "success");
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-4">
        <div>
          <button type="button" class="btn btn-success mb-3"  @click="routeGo(info_page.routeParentName+'/agregar')">
            <i class="mdi mdi-plus me-1"></i> Agregar {{info_page.nameSeccion}}
          </button>
        </div>
      </div>
      <div class="col-md-8">
      
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center fw-normal">
            Mostrar&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;registros
          </label>
        </div>
      </div>
     
    </div>
    <!-- Table -->
    <div class="table-responsive mb-0">
      <table class="table table-centered datatable table-card-list">
        <thead class="bg-transparent">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nombre</th>
            <th scope="col">Orden</th>
            <th> Acciones</th>
          </tr>
        </thead>
        <draggable v-model="invoicelist" tag="tbody">
          <tr v-for="item, idx in invoicelist" :key="item.order">
            <td scope="row">{{ (idx+1) }}</td>
            <td>{{ item.arreglo.name }}</td>
            <td>{{ item.order =  (idx+1) }}</td>
            <td> 
              <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <a
               
                class="px-2 text-primary"
                v-b-tooltip.hover
                title="Editar"
                @click="routeGo(info_page.routeParentName+'/editar/'+item.id)"
              >
                <i class="uil uil-pen font-size-18"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a
                href="javascript:void(0);"
                class="px-2 text-danger"
                v-b-tooltip.hover
                title="Eliminar"
                 @click="deleteElement(item.id)"
              >
                <i class="uil uil-trash-alt font-size-18"></i>
              </a>
            </li>
          </ul></td>
          </tr>
        </draggable>
      </table>
      
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <button type="button" class="btn btn-success mb-3"  @click="updateForm()">
            <i class="mdi mdi-save me-1"></i> Guardar cambios 
          </button>
        </div>
      </div>
    </div>
  </Layout>
</template>
